<template>
  <div id="result-comments">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div class="columns is-centered is-multiline">
        <div class="column is-8">
          <b-field label="Term">
            <b-select
              id="term-id"
              v-model="termId"
              placeholder="Select a option"
              expanded
            >
              <option v-for="term in terms" :key="term.id" :value="term.id">
                {{ term.sessionTitle }}
              </option>
            </b-select>
          </b-field>
        </div>

        <div class="column is-8">
          <p class="title is-6 is-bolder">Student's Attendance</p>
          <div class="media has-background-white-ter p-2">
            <div class="media-left">
              <figure class="image is-48x48">
                <img
                  class="is-rounded"
                  :src="student.imageUrl"
                  alt="avatar"
                  v-if="student.imageUrl"
                />
                <img
                  class="is-rounded"
                  src="@/assets/images/default_avatar.png"
                  alt="avatar"
                  v-else
                />
              </figure>
            </div>
            <div class="media-content">
              <p class="title is-4" style="padding-top: 8px">
                {{ student.name }}
              </p>
            </div>
          </div>
        </div>

        <div class="column is-8">
          <div class="columns is-mobile is-multiline mt-1">
            <div
              class="column"
              v-for="(record, month) in student.termAttendance.records"
              :key="month"
            >
              <SdInput
                type="text"
                :label="getMonth(month)"
                v-model="record.total"
              />
            </div>
          </div>
        </div>

        <div class="column is-8">
          <div class="buttons is-right">
            <button class="button is-success" @click="takeFastAttendance()">
              Submit
            </button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SdInput from '../../components/SdInput'
import { crudNotification } from '../../assets/js/notification'

export default {
  data() {
    return {
      schoolId: null,
      pageTitle: 'Previous Result Attendance',
      terms: [],
      termId: '',
      attendances: [],
      student: { termAttendance: {} },
      monthsOfTheYear: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December',
      },
    }
  },
  watch: {
    termId() {
      this.fetchAttendance()
    },
    student(data) {
      this.terms = data.resultTerms

      const allowedTerms = data.termAttendance.term.months

      const records = Object.fromEntries(
        Object.entries(data.termAttendance.records).filter((record) =>
          allowedTerms.includes(parseInt(record[0]))
        )
      )

      if (Object.keys(records).length !== 0) {
        data.termAttendance.records = records
      } else {
        allowedTerms.forEach((id) => {
          records[id] = { days: {}, total: null }
        })
        data.termAttendance.records = records
      }

      this.$store.commit('setSubMenus', [
        {
          name: 'Result Info',
          route: `/school/${this.schoolId}/school_class/${data.schoolClassId}/result_info/${data.id}/term_id/${this.termId}`,
        },
      ])
    },
  },
  components: {
    SdInput,
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)
    this.schoolId = parseInt(this.$route.params.school_id)
    this.studentId = parseInt(this.$route.params.student_id)
    this.termId = parseInt(this.$route.params.term_id)
  },
  methods: {
    getMonth(index) {
      return this.monthsOfTheYear[index]
    },
    takeFastAttendance() {
      this.attendances.push({
        student_id: this.student.id,
        attendance: this.student.termAttendance,
      })

      this.$apollo
        .mutate({
          mutation: gql`
            mutation FastAttendanceQuery($termId: ID!, $records: JSON!) {
              fastAttendance(input: { termId: $termId, records: $records }) {
                errors
              }
            }
          `,
          variables: {
            termId: parseInt(this.termId),
            records: this.attendances,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.fastAttendance.errors,
            'Successfully updated.'
          )
        })
        .catch((error) => {
          console.error(error)
        })
    },
    fetchAttendance() {
      this.$apollo.addSmartQuery('student', {
        query: gql`
          query StudentTermQuery($student_id: ID!, $term_id: ID!) {
            studentTerm(studentId: $student_id, termId: $term_id) {
              id
              name
              schoolClassId
              termAttendance {
                id
                records
                term {
                  id
                  months
                }
              }
              resultTerms {
                id
                title
                sessionTitle
              }
            }
          }
        `,
        variables: {
          student_id: this.studentId,
          term_id: this.termId,
        },
        fetchPolicy: 'cache-and-network',
        update: (data) => data.studentTerm,
        error(error) {
          this.$buefy.notification.open({
            duration: 5000,
            message: error.message,
            position: 'is-top-right',
            type: 'is-danger',
            hasIcon: true,
          })
        },
      })
    },
  },
}
</script>
